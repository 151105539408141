<template>
	<section class="content">
		<div class="card-back w-100">
			<div class="card card-primary card-mx-auto">
				<div class="card-body">
					<div class="row border-bottom custom-tab">
						<div class="col-6 col-sm-auto mb-2 pt-3 px-0 cursor-pointer" :class="(notifikasi == 'kiriman' ? 'active text-dark' : 'text-secondary')" @click="toggleNotif('kiriman')">
							<h6 class="d-none d-sm-block mx-5 mb-0 px-5 pb-3 font-weight-bold">Kiriman</h6>
							<h6 class="d-block d-sm-none text-center mb-0 pb-3 font-weight-bold">Kiriman</h6>
						</div>
						<div class="col-6 col-sm-auto mb-2 pt-3 px-0 cursor-pointer" :class="(notifikasi == 'informasi' ? 'active text-dark' : 'text-secondary')" @click="toggleNotif('informasi')">
							<h6 class="d-none d-sm-block mx-5 mb-0 px-5 pb-3 font-weight-bold">Informasi</h6>
							<h6 class="d-block d-sm-none text-center mb-0 pb-3 font-weight-bold">Informasi</h6>
						</div>
					</div>
					<div v-if="notifikasi == 'kiriman'">
						<div class="row border-bottom py-4 px-3" v-if="!listKiriman.length">
							<div class="col-10 col-sm-6 col-lg-4 m-auto text-center">
								<img class="w-100" :src="empty">
								<h4 class="text-center font-weight-bold">Notifikasi Kosong</h4>
								<h6 class="text-center font-weight-normal">Belum ada notifikasi terbaru saat ini</h6>
							</div>
						</div>
						<!--
						<div class="row border-bottom py-4 px-3" v-for="index in 5">
							<div class="col-auto pt-2">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm">
								<div class="d-flex justify-content-between">
									<h6 class="font-weight-bold">Paket bermasalah</h6>
									<small class="text-secondary">14 September</small>
								</div>
								<h6 class="mb-0">Paket dengan nomor resi IDE683002022 sedang bermasalah.</h6>
							</div>
						</div>
						 -->
						<a :href="item.link" target="_blank" class="row border-bottom py-4 px-3 text-dark" v-for="item in listKiriman">
							<div class="col-auto pt-2">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm">
								<div class="d-flex justify-content-between">
									<h6 class="font-weight-bold">{{ item.title }}</h6>
									<small class="text-secondary">{{ formatTanggal(item.created_at) }}</small>
								</div>
								<h6 class="mb-0">{{ item.body }}</h6>
							</div>
						</a>
					</div>
					<div class="pt-3 text-center" v-if="listKiriman.length && notifikasi == 'kiriman'">
						<h6 v-if="loadingKiriman" class="text-primary mb-0">Memuat...</h6>
						<button v-else-if="!finishKiriman" class="btn btn-outline-warning" @click="loadKiriman">Muat lebih banyak</button>
					</div>
					<div v-if="notifikasi == 'informasi'">
						<div class="row border-bottom py-4 px-3" v-if="!listInformasi.length">
							<div class="col-10 col-sm-6 col-lg-4 m-auto text-center">
								<img class="w-100" :src="empty">
								<h4 class="text-center font-weight-bold">Notifikasi Kosong</h4>
								<h6 class="text-center font-weight-normal">Belum ada notifikasi terbaru saat ini</h6>
							</div>
						</div>
 						<!--
						<div class="row border-bottom py-4 px-3">
							<div class="col-auto mt-4 pt-2">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm">
								<small class="text-secondary">14 September</small>
								<h6 class="font-weight-bold mt-2">Pengumuman Pemenang Giveaway BosCOD Spesial 10K Shippers!</h6>
								<h6>IDExpress sudah normal kembalo. Resi lama sudah berhasil ditracking. Jika ada kendala tracking, bisa diinfokan ke tim kami via livechat</h6>
							</div>
							<div class="col-lg-3 col-md-4 ml-auto d-flex align-items-center">
								<img src="https://i.ibb.co/kcR55V4/Notifikasi-Pict.png" alt="Promotion Image" class="w-100">
							</div>
						</div>
						 -->
						<a :href="item.link" target="_blank" class="row border-bottom py-4 px-3 text-dark" v-for="item in listInformasi">
							<div class="col-auto pt-2" v-if="!item.link">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm" v-if="!item.link">
								<div class="d-flex justify-content-between">
									<h6 class="font-weight-bold">{{ item.title }}</h6>
									<small class="text-secondary">{{ formatTanggal(item.created_at) }}</small>
								</div>
								<h6 class="mb-0">{{ item.body }}</h6>
							</div>
							<div class="col-auto mt-4 pt-2" v-if="item.link">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm" v-if="item.link">
								<small class="text-secondary">{{ formatTanggal(item.created_at) }}</small>
								<h6 class="font-weight-bold mt-2">{{ item.title }}</h6>
								<h6>{{ item.body }}</h6>
							</div>
							<div class="col-lg-3 col-md-4 ml-auto d-flex align-items-center" v-if="item.link">
								<img :src="item.link" alt="Promotion Image" class="w-100">
							</div>
						</a>
					</div>
					<div class="pt-3 text-center" v-if="listInformasi.length && notifikasi == 'informasi'">
						<h6 v-if="loadingInformasi" class="text-primary mb-0">Memuat...</h6>
						<button v-else-if="!finishInformasi" class="btn btn-outline-warning" @click="loadInformasi">Muat lebih banyak</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import moment from "moment";

export default {
data() {
	return {
		notifikasi: 'kiriman',
		empty: '',
		listKiriman: [],
		loadingKiriman: false,
		startKiriman: 0,
		finishKiriman: false,
		listInformasi: [],
		loadingInformasi: false,
		startInformasi: 0,
		finishInformasi: false,
	};
},
created: function () {
	this.empty = require('../assets/img/Empty-state.svg');
	this.loadKiriman();
	this.loadInformasi();
},
methods: {
	toggleNotif(tipe) {
		this.notifikasi = tipe;
	},
	formatTanggal(date) {
		return moment(date).lang("id").format('DD MMMM');
	},
	loadKiriman() {
		this.loadingKiriman = true;
		authFetch(`/report/notification?message_type=private` + (this.startKiriman > 0 ? `&start=` + this.startKiriman : ``)).then((res) => {
			res.json().then((json) => {
				if (json.success) {
					if (this.start == 0) {
						this.listKiriman = json.data;
					} else if (json.data.length == 0) {
						this.finishKiriman = true;
					} else {
						this.listKiriman = this.listKiriman.concat(json.data);
					}

					this.startKiriman = this.startKiriman + 10;
					this.loadingKiriman = false;
				} else {
					// this.listKiriman = [];
				}
			});
		});
	},
	loadInformasi() {
		this.loadingInformasi = true;
		authFetch(`/report/notification?message_type=public&status=1` + (this.startInformasi > 0 ? `&start=` + this.startInformasi : ``)).then((res) => {
			res.json().then((json) => {
				if (json.success) {
					if (this.start == 0) {
						this.listInformasi = json.data;
					} else if (json.data.length == 0) {
						this.finishInformasi = true;
					} else {
						this.listInformasi = this.listInformasi.concat(json.data);
					}

					this.startInformasi = this.startInformasi + 10;
					this.loadingInformasi = false;
				} else {
					// this.listKiriman = [];
				}
			});
		});
	},
},
components: {},
mounted() {},
};
</script>
<style type="text/css">
.custom-tab div:nth-child(odd):after {
	margin-left: auto;
}
.custom-tab div:nth-child(even):after {
	margin-right: auto;
}
.custom-tab div:not(.active):after {
	content: '';
	display: block;
	height: 2.5px;
	width: 0px;
	background: #000;
	transition: all .15s linear;
}
.custom-tab div.active:after {
	content: '';
	display: block;
	height: 2.5px;
	width: 100%;
	background: #000;
	transition: all .15s linear;
}
</style>